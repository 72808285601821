<template>
  <div>
    <CSTabBar :checked-child-tab="checkedChildTab" :checked-tab="checkedTab" :tabbar="tabBar"
              @changeTabBar="changeTabBar"
    />
    <div v-if="checkedTab === 1 && checkedChildTab === 2" class="filter-panel">
      <CSSelect>
        <el-date-picker
            v-model="bindLogParams.startDate"
            format="yyyy-MM-dd HH:mm"
            placeholder="请选择"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>
      </CSSelect>
      <span class="inline-gray" style="margin-left: -10px;"> </span>
      <CSSelect>
        <el-date-picker
            v-model="bindLogParams.endDate"
            format="yyyy-MM-dd HH:mm"
            placeholder="请选择"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>
      </CSSelect>
      <input v-model="bindLogParams.search" class="cs-input" placeholder="搜索机场/无人机名称" type="text">
      <CSSelect>
        <select v-model="bindLogParams.isBind">
          <option value="">不限</option>
          <option :value="1">绑定</option>
          <option :value="0">解除绑定</option>
        </select>
      </CSSelect>
      <button class="btn btn-primary" @click="getChangeBindStatusAirportLog()">查询</button>
    </div>
    <div class="result-panel">
      <template v-if="checkedTab === 1">
        <CSTable v-if="checkedChildTab === 1">
          <template v-slot:header>
            <div class="table-header-panel text-right">
              <button class="btn btn-primary sticky-right" @click="openBindDialog">
                <svg aria-hidden="true" class="icon">
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                无人机与机场绑定
              </button>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>绑定时间</th>
              <th>机场名称</th>
              <th>无人机名称</th>
              <th>航点飞行任务</th>
              <th>操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="item in bindAirportDrones" :key="item.id">
              <td class="date-td">{{ item.bindDate }}</td>
              <td>{{ item.airportName }}</td>
              <td>{{ item.droneName }}</td>
              <td>{{ item.taskName || '-' }}</td>
              <td>
                <div class="btn-group">
                  <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      type="button"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu" style="width: 76px">
                    <li v-if="item.droneState === 2" @click="cancelFlight(item.id)">
                      <a>取消飞行</a>
                    </li>
                    <li @click="unbindDrone(item)">
                      <a>解除绑定</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </CSTable>

        <CSTable v-if="checkedChildTab === 2" :theadTop="filterHeight">
          <template v-slot:thead>
            <tr>
              <th>操作时间</th>
              <th>机场名称</th>
              <th>无人机名称</th>
              <th>绑定/解绑</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="item in bindLogs" :key="item.id">
              <td class="date-td">{{ item.operationDate }}</td>
              <td>{{ item.airportName }}</td>
              <td>{{ item.droneName }}</td>
              <td>
                {{ item.isBind ? '绑定' : '解绑' }}
              </td>
            </tr>
          </template>

        </CSTable>
      </template>
      <CSTable v-if="checkedTab === 2">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button class="btn btn-primary sticky-right" @click="openDronesDialog()">
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              无人机
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>添加时间</th>
            <th>无人机名称</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="drone in droneList" :key="drone.id">
            <td class="date-td">{{ drone.createTime }}</td>
            <td>
              <div style="position: relative;display: inline-block;min-width: 60px;">
                {{ drone.name }}
                <span :class="['drone-state-label', {
                                    warning: drone.state === 4
                                }]">{{ droneState[drone.state] || '-' }}</span>
              </div>
            </td>
            <td>
              <div v-if="[1, 0].includes(drone.state)" class="btn-group">
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>
                <ul class="dropdown-menu" style="width: 76px">
                  <li @click="openDronesDialog(drone)">
                    <a>修改无人机</a>
                  </li>
                  <li @click="deleteDrone(drone.id)">
                    <a>删除无人机</a>
                  </li>
                </ul>
              </div>
              <template v-else>-</template>
            </td>
          </tr>
        </template>
      </CSTable>
      <CSTable v-if="checkedTab === 3">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button class="btn btn-primary sticky-right" @click="openDroneAirportDialog()">
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              机场
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>添加时间</th>
            <th>机场名称</th>
            <th>机场位置</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="item in airportList" :key="item.id">
            <td class="date-td">{{ item.createTime || '-' }}</td>
            <td>{{ item.name || '-' }}</td>
            <td>{{ item.specificLocation }}</td>
            <td>
              <div v-if="[1, 0, 4].includes(item.droneState)" class="btn-group">
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>
                <ul class="dropdown-menu" style="width: 76px">
                  <li @click="sendCommandBtn(item,0)">
                    <a>打开机场</a>
                  </li>
                  <li @click="sendCommandBtn(item,1)">
                    <a>关闭机场</a>
                  </li>
                  <li @click="openDroneAirportDialog(item)">
                    <a>修改机场</a>
                  </li>
                  <li @click="deleteAirport(item.id)">
                    <a>删除机场</a>
                  </li>
                </ul>
              </div>
              <template v-else>-</template>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination v-if="checkedTab !== 4"/>


      <div v-if="checkedTab === 4" class="table-header-panel">
        <div class="target-con">
          机场设置
        </div>
        <hr>
        <div style="height: 20px;padding: 20px;padding-top: 0">
          <span style="font-size: 14px;margin-right: 30px;vertical-align: -2px">指定人员添加WiFi设备</span>
          <div class="tree-wrap">
            <CSSelect>
              <div
                  :class="['room-select', {'unselect': Object.keys(checkedStaffs).length === 0}]"
                  @click.stop="staffTreeVisible = true"
              >
                <template v-if="Object.keys(checkedStaffs).length > 0">
                  已选{{ Object.keys(checkedStaffs).length }}人
                </template>
                <template v-else>
                  请选择
                </template>
              </div>
              <div @click.stop>
                <TreePanel v-show="staffTreeVisible">
                  <CSTree :tree="staffTreeData" @change="changeStaff"/>
                </TreePanel>
              </div>
            </CSSelect>
          </div>
        </div>
        <div class="tip"
             style="padding-top: 20px;color: #999;font-size: 14px;margin-left: 20px;margin-bottom: 20px">
          <svg
              aria-hidden="true"
              class="icon"
          >
            <use xlink:href="#icon-menua-zu92"></use>
          </svg>
          被指定的人员可在智科运营小程序上添加机场WiFi设备。
        </div>
        <div style="margin-left: 20px">
          <button class="btn btn-primary" @click="setDroneWifiConfig">保存</button>
        </div>
      </div>

    </div>


    <CSDialog :dialog-title="`${dronesDialog.id ? '修改' : '添加'}无人机`"
              :dialog-visible="dronesDialog.visible"
              :is-submitting="isSubmitting" dialog-confirm-btn-text="保存"
              dialog-width="636px"
              @onClose="dronesDialog.onClose"
              @onConfirm="dronesDialog.onConfirm"
    >
      <template v-slot:dialog-content>
        <div class="dialog-from-panel">
          <!--                    <div class="dialog-from-field">
                                  <div class="dialog-from-field-name">
                                      关联第三方无人机
                                  </div>
                                  <div>
                                      <input type="text" placeholder="请输入">
                                  </div>
                              </div>-->
          <div class="dialog-from-field">
            <div class="dialog-from-field-name">
              SN码
            </div>
            <div>
              <input v-model="dronesDialog.data.sn" :disabled="dronesDialog.id" placeholder="请输入"
                     type="text">
            </div>
          </div>
          <div class="dialog-from-field">
            <div class="dialog-from-field-name">
              无人机名称
            </div>
            <div>
              <input v-model="dronesDialog.data.name" maxlength="10" placeholder="限10个字" type="text">
            </div>
          </div>
          <div class="dialog-from-field">
            <div class="dialog-from-field-name">
              pushStream
            </div>
            <div>
              <input v-model="dronesDialog.data.pushStreamAddr" placeholder="请输入" type="text">
            </div>
          </div>
          <div class="dialog-from-field">
            <div class="dialog-from-field-name">
              rtmpPull
            </div>
            <div>
              <input v-model="dronesDialog.data.rtmpPullStreamAddr" placeholder="请输入" type="text">
            </div>
          </div>
          <div class="dialog-from-field">
            <div class="dialog-from-field-name">
              rtsPull
            </div>
            <div>
              <input v-model="dronesDialog.data.rtsPullStreamAddr" placeholder="请输入" type="text">
            </div>
          </div>
          <div class="dialog-from-field">
            <div class="dialog-from-field-name">
              m3u8Pull
            </div>
            <div>
              <input v-model="dronesDialog.data.m3u8PullStreamAddr" placeholder="请输入" type="text">
            </div>
          </div>
          <div class="dialog-from-field">
            <div class="dialog-from-field-name">
              flvPull
            </div>
            <div>
              <input v-model="dronesDialog.data.flvPullStreamAddr" placeholder="请输入" type="text">
            </div>
          </div>
        </div>
      </template>
    </CSDialog>


    <CSDialog :dialog-title="`${droneAirportDialog.id ? '修改' : '添加'}机场`"
              :dialog-visible="droneAirportDialog.visible" :is-submitting="isSubmitting"
              dialog-confirm-btn-text="保存"
              dialog-width="670px"
              @onClose="droneAirportDialog.onClose"
              @onConfirm="droneAirportDialog.onConfirm"
    >
      <template v-slot:dialog-content>
        <div class="dialog-from-panel">
          <!--                    <div class="dialog-from-field">
                                  <div class="dialog-from-field-name">
                                      关联第三方机场
                                  </div>
                                  <div>
                                      <input type="text" placeholder="请输入">
                                  </div>
                              </div>-->
          <div class="dialog-from-field">
            <div class="dialog-from-field-name">
              机场名称
            </div>
            <div>
              <input v-model="droneAirportDialog.data.name" maxlength="10" placeholder="限10个字"
                     type="text">
            </div>
          </div>
          <div class="dialog-from-field">
            <div class="dialog-from-field-name">
              机场位置
            </div>
            <div>
              <ChooseLocation :defaultLocationName="droneAirportDialog.locationName"
                              @changeLocation="changeLocation"/>
            </div>
          </div>
          <div class="dialog-from-field">
            <div class="dialog-from-field-name">
              WiFi设备
            </div>
            <div class="wifi">
              <CSSelect height="40px" i-width="36px" style="width: 403px">
                <select v-model="wifiDeviceId"
                        :style="{color:(wifiDeviceId==''?'#999':''),paddingLeft:'10px'}">
                  <option value="">无</option>
                  <option v-for="item in wifiList" :key="item.deviceId" :value="item.deviceId">
                    {{ item.deviceName }}
                  </option>
                </select>
              </CSSelect>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>


    <CSDialog :dialog-visible="bindDialog.visible" :is-submitting="isSubmitting"
              dialog-confirm-btn-text="保存" dialog-title="无人机与机场绑定"
              dialog-width="660px" @click="hideBindFieldItem"
              @onClose="bindDialog.onClose"
              @onConfirm="bindDialog.onConfirm"
    >
      <template v-slot:dialog-content>
        <div class="dialog-from-panel">
          <div class="dialog-from-field">
            <div class="dialog-from-field-name">
              机场
            </div>
            <div>
              <CSSelect height="40px" i-width="36px">
                <div :class="['from-select', {val: bindDialog.airportName}]"
                     @click.stop="bindDialog.airportVisible = !bindDialog.airportVisible"
                >
                  {{ bindDialog.airportName || '请选择' }}
                </div>
              </CSSelect>
              <div class="from-option">
                <TreePanel v-if="bindDialog.airportVisible" width="400px" @loadMore="loadMoreAirport">
                  <p class="disabled">请选择</p>
                  <p v-for="item in airportList" :key="item.id" @click="pickBindAirport(item)">{{
                      item.name
                    }}</p>
                </TreePanel>
              </div>
            </div>
          </div>
          <div class="dialog-from-field">
            <div class="dialog-from-field-name">
              无人机
            </div>
            <div>
              <CSSelect height="40px" i-width="36px">
                <div :class="['from-select', {val: bindDialog.droneName}]"
                     @click.stop="bindDialog.droneVisible = !bindDialog.droneVisible"
                >
                  {{ bindDialog.droneName || '请选择' }}
                </div>
              </CSSelect>
              <div class="from-option">
                <TreePanel v-if="bindDialog.droneVisible" width="400px" @loadMore="loadMoreDrone">
                  <p class="disabled">请选择</p>
                  <p v-for="item in droneList" :key="item.id" @click="pickBindDrone(item)">{{
                      item.name
                    }}</p>
                </TreePanel>
              </div>
            </div>
          </div>
          <div class="dialog-from-field">
            <div class="dialog-from-field-name">
              航点飞行任务
            </div>
            <div>
              <CSSelect height="40px" i-width="36px">
                <div :class="['from-select', {val: bindDialog.taskName}]"
                     @click.stop="bindDialog.taskVisible = !bindDialog.taskVisible"
                >
                  {{ bindDialog.taskName || '请选择' }}
                </div>
              </CSSelect>
              <div class="from-option">
                <TreePanel v-if="bindDialog.taskVisible" width="400px" @loadMore="loadMoreMission">
                  <p class="disabled">请选择</p>
                  <p v-for="item in missionList" :key="item.id" @click="pickBindMission(item)">
                    {{ item.name }}-航点：{{ item.wayPoints ? JSON.parse(item.wayPoints).length : 0 }}个
                  </p>
                </TreePanel>
              </div>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
  </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import CSTable from "@/components/common/CSTable";
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";
import TreePanel from "@/components/common/TreePanel";
import CSTree from "@/components/common/CSTree";
import {
  addDroneAirportUrl,
  bindAirportAndDroneUrl,
  cancelFlightUrl,
  createDroneUrl,
  deleteDroneAirportUrl,
  deleteDroneUrl,
  editDroneAirportUrl,
  editDroneUrl,
  getIsSettOpenWifiUrl,
  queryBindAirportDronesUrl,
  queryBindAirportLogsUrl,
  queryDroneAirportUrl,
  queryDroneListUrl,
  queryFlightMissionUrl,
  queryWifiDeviceUrl,
  sendCommandUrl,
  setStaffOpenWifiUrl,
  unbindAirportForDroneUrl
} from "@/requestUrl";
import Pagination from "@/components/Pagination";
import ChooseLocation from "@/components/ChooseLocation";
import dayjs from "dayjs";


const droneState = {
  0: '未连接',
  1: '等待飞行',
  2: '飞行中',
  3: '飞行中',
  4: '异常',
};

export default {
  name: "DronesManage",
  components: {TreePanel, ChooseLocation, Pagination, CSSelect, CSDialog, CSTable, CSTabBar, CSTree},
  data() {
    return {
      wifiList: [],
      wifiDeviceId: '',
      filterHeight: 60,
      tabBar: {
        1: {
          name: '无人机与机场绑定',
          children: [
            {id: 1, name: '当前绑定'},
            {id: 2, name: '绑定解绑记录'}
          ]
        },
        2: '无人机管理',
        3: '机场管理',
        4: '设置'
      },
      staffTreeVisible: false,
      checkedStaffs: {},
      staffTreeData: [],
      staffList: [],
      checkedTab: 1,
      checkedChildTab: 1,
      bindDialog: {
        droneVisible: false,
        droneName: null,
        airportName: null,
        airportVisible: false,
        taskName: null,
        taskVisible: false,
        visible: false,
        data: {
          regionCode: this.$vc.getCurrentRegion().code,
          airportId: '',
          droneId: '',
          taskId: '',
        },
        onClose: () => {
          this.bindDialog.visible = false;
        },
        onConfirm() {
        }
      },
      // 无人机弹窗信息
      dronesDialog: {
        id: null,
        visible: false,
        data: {
          regionCode: this.$vc.getCurrentRegion().code,
          name: '',
          sn: '',
          pushStreamAddr: '',
          rtmpPullStreamAddr: '',
          rtsPullStreamAddr: '',
          flvPullStreamAddr: '',
          m3u8PullStreamAddr: '',
        },
        onClose: () => {
          this.dronesDialog.visible = false;
        },
        onConfirm() {
        }
      },
      // 无人机机场弹窗信息
      droneAirportDialog: {
        visible: false,
        id: null,
        locationName: '',
        data: {
          regionCode: this.$vc.getCurrentRegion().code,
          name: '',
          locationId: '',
        },
        onClose: () => {
          this.droneAirportDialog.visible = false;
        },
        onConfirm() {
        }
      },
      droneList: [],
      airportList: [],
      bindAirportDrones: [],
      bindLogs: [],
      missionList: [],
      bindLogParams: {
        startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD 00:00'),
        endDate: dayjs().format('YYYY-MM-DD 23:59'),
        search: '',
        isBind: '',
      },
      isSubmitting: false,
      droneState,
      pageParams: {
        pageNo: 1,
        pageSize: 10
      },
      regionId: this.$vc.getCurrentRegion().code
    }
  },
  updated() {
    const filterPanel = document.getElementsByClassName('filter-panel');
    console.log(filterPanel, '溴铵则得到')
    if (filterPanel.length > 0) {
      this.filterHeight = filterPanel[0].offsetHeight;
    }
  },
  mounted() {
    this.getWiFi();
    this.changeTabBar(1);
    window.addEventListener('resize', () => {
      const filterPanel = document.getElementsByClassName('filter-panel');
      if (filterPanel.length > 0) {
        this.filterHeight = filterPanel[0].offsetHeight;
      }
    })
    this.$vc.on(this.$route.path, 'pagination_page', 'event', (page) => {
      this.pageParams.pageNo = page;
      switch (this.checkedTab) {
        case 1:
          if (this.checkedChildTab === 1) {
            this.getBindAirportDrones();
          } else {
            this.getChangeBindStatusAirportLog();
          }
          break;
        case 2:
          this.queryDrones(page);
          break;
        case 3:
          this.queryDroneAirports(page)
          break;
        case 4:
          break;
      }
    })

    document.body.addEventListener('click', () => {
      this.staffTreeVisible = false;
    })
    this.getStaffList();
  },
  methods: {
    sendCommandBtn(data, state) {
      let str = '确定打开机场吗？';
      if (state == 1) {
        str = '确定关闭机场吗？';
      }

      this.$CSDialog.confirm({
        title: "提示",
        message: str,
        onConfirm: () => {
          this.$fly
              .get(`${sendCommandUrl}/${data.id}/${state}`)
              .then((res) => {
                if (res.code !== 0) {
                  return;
                }
                this.$CSDialog.instance.closeDialog();
                this.queryDroneAirports();
                if (state == 0) {
                  this.$vc.toast("正在开启机场");
                } else {
                  this.$vc.toast("正在关闭机场");
                }

              });
        },
      });
    },
    getOpenWifiStaff() {
      return this.$fly.get(getIsSettOpenWifiUrl, {
        staffUserId: '',
        regionId: this.regionId,
      })
          .then(res => res.data || [])
    },
    getWiFi() {
      this.$fly.get(queryWifiDeviceUrl + '/' + this.$vc.getCurrentRegion().code).then(res => {
        if (res.code != 0) {
          return
        }
        this.wifiList = res.data;
      })
    },
    // 获取员工列表
    async getStaffList() {
      let canOpenRoomStaffs = {};
      this.checkedStaffs = {};
      const canOpenRoomRes = await this.getOpenWifiStaff();
      canOpenRoomRes.forEach(staff => {
        this.checkedStaffs[staff.staffUserId] = {
          id: staff.id,
          departmentId: staff.departmentId, //必填，部门编号
          departmentName: staff.departmentName, //必填，部门名称
          staffUserId: staff.staffUserId, //必填，员工编号
          staffName: staff.staffName, //必填，员工姓名
          staffOfficeName: staff.staffOfficeName, //必填，员工职位
          state: staff.state //设置状态  0-已设置  1-未设置
        };
        staff.state = Number(!staff.state);
        canOpenRoomStaffs[staff.staffUserId] = staff;
      });
      this.canOpenRoomStaffs = canOpenRoomStaffs;
      const departments = await this.$vc.getAllDepartmentList({});
      departments.map(async duty => {
        let children = await this.$vc.getAllStaffList({isOnTheJob: true, departmentCode: duty.code});
        children = children.map(child => {
          child.checked = false;
          if (canOpenRoomStaffs.hasOwnProperty(child.id)) {
            child.openId = canOpenRoomStaffs[child.id].id;
            child.checked = !!canOpenRoomStaffs[child.id].state;
          }
          child.allowCheck = true;
          return child;
        })
        const item = {
          ...duty,
          children,
          checked: false,
          allowCheck: true,
        };
        this.staffTreeData.push(item);
      })
    },
    changeStaff({key: index, source}) {
      const item = source[index];
      const checked = item.checked;
      let {checkedStaffs} = this;
      item.checked = !checked;
      if (item.children) {
        item.children.forEach(child => {
          this.changeCheckedStaff(item.checked, checkedStaffs, child);
          child.checked = item.checked;
        })
      } else {
        this.changeCheckedStaff(item.checked, checkedStaffs, item);
      }
      this.checkedStaffs = checkedStaffs;
    },
    /**
     * 更改选中的员工
     * @param {Boolean} checked 是否选中
     * @param {Object} checkedStaffs 所有选中的员工对象
     * @param {Object} item 当前选择的员工
     * */
    changeCheckedStaff(checked, checkedStaffs, item) {
      let {canOpenRoomStaffs} = this;
      if (checked) {
        const staff = {
          departmentId: item.departmentCode, //必填，部门编号
          departmentName: item.departmentName, //必填，部门名称
          staffUserId: item.id, //必填，员工编号
          staffName: item.name, //必填，员工姓名
          staffOfficeName: item.jobTitle, //必填，员工职位
          state: 0 //设置状态  0-已设置  1-未设置
        };
        if (item.openId) {
          staff.id = item.openId;
        }
        checkedStaffs[item.id] = staff;
      } else {
        if (canOpenRoomStaffs[item.id]) {
          checkedStaffs[item.id].state = 1;
        } else {
          delete checkedStaffs[item.id];
        }
      }
    },
    setDroneWifiConfig() {
      const {checkedStaffs} = this;
      let params = Object.assign(Object.values(checkedStaffs).map(staff => {
        staff.regionId = this.regionId;
        return staff;
      }));
      this.$fly.post(setStaffOpenWifiUrl, params)
          .then(res => {
            console.log('保存：', res)
            if (res.code !== 0) {
              return;
            }
            this.$vc.toast('设置成功');
            this.getStaffList();
          })
    },
    hideBindFieldItem() {
      this.bindDialog.airportVisible = false;
      this.bindDialog.droneVisible = false;
      this.bindDialog.taskVisible = false;
    },
    pickBindDrone(itemInfo) {
      this.bindDialog.data.droneId = itemInfo.id;
      this.bindDialog.droneName = itemInfo.name;
      this.bindDialog.droneVisible = false;
    },
    pickBindAirport(itemInfo) {
      this.bindDialog.data.airportId = itemInfo.id;
      this.bindDialog.airportName = itemInfo.name;
      this.bindDialog.airportVisible = false;
    },
    pickBindMission(itemInfo) {
      this.bindDialog.data.taskId = itemInfo.id;
      this.bindDialog.taskName = itemInfo.name;
      this.bindDialog.taskVisible = false;
    },
    // 获取飞行任务
    getFlightMission(pageNo = 1, pageSize = 10) {
      return this.$fly.post(queryFlightMissionUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        pageNo,
        pageSize
      })
          .then(res => res)
          .catch(() => [])
    },
    loadRequest(propertyName, fnName) {
      let pageNo = 1;
      return async () => {
        if (this[propertyName].length !== 0 && this[propertyName].length % 10 === 0) {
          pageNo++;
          const res = await this[fnName](pageNo);
          this[propertyName].push(...res.data.datas);
        }
      }
    },
    openBindDialog() {
      Promise.all([
        this.queryDronesRequest(),
        this.queryDroneAirportsRequest(),
        this.getFlightMission()
      ])
          .then(([{data: {datas: dronesList}}, {data: {datas: airportList}}, missionRes]) => {
            this.droneList = dronesList;
            this.airportList = airportList;
            this.missionList = missionRes.data.datas ?? [];
          });
      this.loadMoreDrone = this.loadRequest('droneList', 'queryDronesRequest');
      this.loadMoreAirport = this.loadRequest('airportList', 'queryDroneAirportsRequest');
      this.loadMoreMission = this.loadRequest('missionList', 'getFlightMission');
      this.bindDialog.visible = true;
      this.bindDialog.droneName = null;
      this.bindDialog.airportName = null;
      this.bindDialog.taskName = null;
      this.bindDialog.data = {
        regionCode: this.$vc.getCurrentRegion().code,
        airportId: '',
        droneId: '',
        taskId: '',
      };
      this.bindDialog.onConfirm = () => {
        this.bindAirportAndDrone();
      }
    },
    bindAirportAndDrone() {
      this.$fly.post(bindAirportAndDroneUrl, this.bindDialog.data)
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            this.$vc.toast('绑定成功');
            this.getBindAirportDrones();
            this.bindDialog.onClose();
          })
    },
    // 取消飞行
    cancelFlight(id) {
      this.$CSDialog.confirm({
        title: '提示',
        message: `确定取消飞行吗？`,
        onConfirm: () => {
          this.$fly.get(cancelFlightUrl, {id})
              .then(res => {
                if (res.code === 0) {
                  this.$CSDialog.instance.closeDialog();
                  this.$vc.toast('取消飞行成功');
                  this.getBindAirportDrones();
                }
              })
        }
      })

    },
    // 解绑无人机与机场
    unbindDrone(item) {
      this.$CSDialog.confirm({
        title: '提示',
        message: `确定将${item.airportName}与${item.droneName}解除绑定吗？`,
        onConfirm: () => {
          this.$fly.get(unbindAirportForDroneUrl, {
            id: item.id
          })
              .then(res => {
                if (res.code === 0) {
                  this.$CSDialog.instance.closeDialog();
                  this.$vc.toast('解除绑定成功');
                  this.getBindAirportDrones();
                }
              })
        }
      })

    },
    changeTabBar(id, type) {
      console.log(id, '===', type)
      this.pageParams.pageNo = 1;
      const childTabHandler = {
            1: this.getBindAirportDrones,
            2: this.getChangeBindStatusAirportLog
          },
          tabBarHandler = {
            1: () => {
              childTabHandler[1]();
            },
            2: this.queryDrones,
            3: this.queryDroneAirports,
            4: this.query
          };
      if (type === 'checkedChildTab') {
        this.checkedChildTab = id;
        childTabHandler[id]();
      } else {
        tabBarHandler[id]();
        this.checkedTab = id;
      }
    },
    query() {

    },
    queryDronesRequest(pageNo = 1, pageSize = 10) {
      return this.$fly.post(queryDroneListUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        pageNo,
        pageSize
      })
          .then(res => res)
          .catch(() => []);
    },
    // 查询无人机
    async queryDrones(pageNo = this.pageParams.pageNo, pageSize = 10) {
      const res = await this.queryDronesRequest(pageNo, pageSize);
      if (pageNo === this.pageParams.pageNo) {
        this.$vc.emit(this.$route.path, "pagination", "init", {
          total: res.data.total,
          pageSize,
          currentPage: pageNo
        })
      }
      this.droneList = res.data.datas;
    },
    /**
     * 删除无人机
     * @param {Number | String} id 无人机id
     * */
    deleteDrone(id) {
      this.$CSDialog.confirm({
        title: '提示',
        message: '确定删除无人机吗？',
        onConfirm: () => {
          this.$fly.get(deleteDroneUrl, {
            id
          })
              .then(res => {
                if (res.code === 0) {
                  this.$vc.toast('删除成功');
                  this.queryDrones();
                  this.$CSDialog.instance.closeDialog();
                }
              })
        }
      })
    },
    // 创建/编辑无人机
    createOrEditDrones() {
      const params = {
        ...this.dronesDialog.data
      };
      const validateRes = this.$vc.validate.validate({
        configInfo: params
      }, {
        'configInfo.name': [
          {
            limit: "required",
            param: "",
            errInfo: "请输入无人机名称",
          },
        ],
        'configInfo.sn': [
          {
            limit: "required",
            param: "",
            errInfo: "请输入无人机sn码",
          },
        ],
      })
      if (!validateRes) {
        this.$vc.message(this.$vc.validate.errInfo)
      }
      if (this.dronesDialog.id) {
        params.id = this.dronesDialog.id;
        delete params.sn;
        delete params.regionCode;
      }
      this.isSubmitting = true;
      this.$fly.post(params.id ? editDroneUrl : createDroneUrl, params)
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            this.$vc.toast(`${params.id ? '修改' : '添加'}无人机成功`);
            this.queryDrones();
            this.dronesDialog.onClose();
          })
          .finally(() => {
            this.isSubmitting = false;
          })
    },
    /**
     * 初始化无人机弹窗数据
     * @param {Object | Undefined} data 无人机信息
     * */
    initDronesDialogData(data) {
      if (data) {
        for (let key in this.dronesDialog.data) {
          this.dronesDialog.data[key] = data[key] ?? this.dronesDialog.data[key];
        }
        this.dronesDialog.id = data.id;
        this.dronesDialog.onConfirm = this.createOrEditDrones;
      } else {
        this.dronesDialog.id = null;
        this.dronesDialog.data = {
          regionCode: this.$vc.getCurrentRegion().code,
          name: '',
          sn: '',
          pushStreamAddr: '',
          rtmpPullStreamAddr: '',
          rtsPullStreamAddr: '',
          flvPullStreamAddr: '',
          m3u8PullStreamAddr: '',
        }
        this.dronesDialog.onConfirm = this.createOrEditDrones;
      }
    },
    // 打开无人机弹窗
    openDronesDialog(data) {
      this.initDronesDialogData(data);
      this.dronesDialog.visible = true;
    },
    /**
     * 修改位置
     * @param {Object} location 位置信息
     * */
    changeLocation(location) {
      this.droneAirportDialog.data.locationId = location.id;
    },
    /**
     * 初始化无人机机场弹窗数据
     * @param {Object | Undefined} data 无人机机场信息
     * */
    initDroneAirportDialogData(data) {
      if (data) {
        console.log('Wang');
        console.log(data);
        for (let key in this.droneAirportDialog.data) {
          this.droneAirportDialog.data[key] = data[key] ?? this.droneAirportDialog.data[key];
        }
        this.droneAirportDialog.id = data.id;
        this.wifiDeviceId = data.wifiDeviceId ? data.wifiDeviceId:'';
        this.droneAirportDialog.locationName = data.specificLocation;
        this.droneAirportDialog.onConfirm = this.editAirport;
      } else {
        this.droneAirportDialog.id = null;
        this.droneAirportDialog.data = {
          regionCode: this.$vc.getCurrentRegion().code,
          name: '',
          locationId: '',
        }
        this.wifiDeviceId = '';
        this.droneAirportDialog.locationName = '';
        this.droneAirportDialog.onConfirm = this.editAirport;

      }
    },
    editAirport() {
      const params = this.droneAirportDialog.data;
      const validateRes = this.$vc.validate.validate({
        configInfo: params
      }, {
        'configInfo.name': [
          {
            limit: "required",
            param: "",
            errInfo: "请输入机场名称",
          },
        ],
        'configInfo.locationId': [
          {
            limit: "required",
            param: "",
            errInfo: "请选择位置",
          },
        ],
      })
      if (!validateRes) {
        this.$vc.message(this.$vc.validate.errInfo)
      }
      if (this.droneAirportDialog.id) {
        params.id = this.droneAirportDialog.id;
        delete params.regionCode;
      }
      this.isSubmitting = true;
      this.$fly.post(params.id ? editDroneAirportUrl : addDroneAirportUrl, {
        ...params,
        wifiDeviceId: this.wifiDeviceId
      })
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            this.$vc.toast(`${params.id ? '修改' : '添加'}无人机机场成功`);
            this.droneAirportDialog.onClose();
            this.queryDroneAirports();
          })
          .finally(() => {
            this.isSubmitting = false;
          })
    },
    // 打开机场弹窗
    openDroneAirportDialog(data) {
      this.getWiFi();
      this.droneAirportDialog.visible = true;
      this.initDroneAirportDialogData(data);
    },
    /**
     * 删除无人机机场
     * @param {Number | String} id 无人机机场id
     * */
    deleteAirport(id) {
      this.$CSDialog.confirm({
        title: '提示',
        message: '确定删除无人机机场吗？',
        onConfirm: () => {
          this.$fly.get(deleteDroneAirportUrl, {
            id
          })
              .then(res => {
                if (res.code === 0) {
                  this.$vc.toast('删除成功');
                  this.queryDroneAirports();
                  this.$CSDialog.instance.closeDialog();
                }
              })
        }
      })
    },
    queryDroneAirportsRequest(pageNo = 1, pageSize = 10) {
      return this.$fly.post(queryDroneAirportUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        pageNo,
        pageSize
      })
          .then(res => res)
          .catch(err => err);
    },
    // 查询无人机机场
    async queryDroneAirports(pageNo = this.pageParams.pageNo, pageSize = 10) {
      const res = await this.queryDroneAirportsRequest(pageNo, pageSize);
      if (pageNo === 1) {
        this.$vc.emit(this.$route.path, "pagination", "init", {
          total: res.data.total,
          pageSize,
          currentPage: pageNo
        })
      }
      this.airportList = res.data.datas;
    },
    // 查询的绑定机场的无人机
    getBindAirportDrones(pageNo = 1, pageSize = 10) {
      this.$fly.post(queryBindAirportDronesUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        pageNo,
        pageSize
      })
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                pageSize,
                currentPage: pageNo
              })
            }
            this.bindAirportDrones = res.data.datas;
          })
    },
    // 查询绑定、解绑机场记录
    getChangeBindStatusAirportLog(pageNo = 1, pageSize = 10) {
      this.$fly.post(queryBindAirportLogsUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        pageNo,
        pageSize,
        ...this.bindLogParams
      })
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                pageSize,
                currentPage: pageNo
              })
            }
            this.bindLogs = res.data.datas;
          })
    }
  }
}
</script>

<style lang="stylus" scoped>
.dropdown-menu
  li
    a
      width 100%

.dialog-from
  &-panel
    padding 30px
    font-size 24px

  &-field
    &:not(:last-of-type)
      margin-bottom 22px

    &-name
      text-align right
      width calc(100% - 40px - 400px)
      margin-right 40px
      vertical-align middle
      display inline-block

      & + div
        vertical-align middle
        display inline-block
        width 400px
        position relative

        input
          border 1px solid #979797
          border-radius 4px
          height 40px
          padding 0 10px
          width 100%

        .from-select
          width 360px
          padding 0 5px
          color #999

          &.val
            color #000

        .from-option
          p
            margin-bottom 0
            padding 0 5px
            margin-left -10px
            margin-right -10px
            cursor pointer

            &.disabled
              color #999
              background #fff
              cursor not-allowed

            &:first-of-type
              margin-top -10px

            &:last-of-type
              margin-bottom -10px


            &:hover
              background #4e99f5
              color #fff

.drone-state-label
  min-width 30px
  min-height 16px
  display inline-block
  line-height 16px
  padding 2px 5px
  background #D8FFF8
  color #00B694
  border-radius 10px
  font-size 10px
  position absolute
  right 0
  top 0
  transform translate(100%, -50%)

  &.warning
    color #FF9F00
    background #FFEBC9

.config-panel
  background #fff
  padding 25px 20px
  min-height 300px

  & > div
    font-size 14px
    color #000

    & > span
      display inline-block
      margin-right 30px
      vertical-align middle

    &:not(:last-of-type)
      margin-bottom 20px

    &.tip
      color #999

.tree-wrap
  max-width 250px
  position relative
  display inline-block
  vertical-align middle

  .cs-input
    width 215px

.room-select
  width 215px
  overflow hidden
  height 40px
  display inline-flex !important
  flex-flow row nowrap
  align-items center
  line-height 40px
  padding 0 6px
  position relative

  &.unselect
    color #999

  span
    height 30px
    line-height 30px
    background #1ab394
    padding 0 10px
    text-align center
    display inline-block
    vertical-align middle
    color #fff
    border-radius 15px

    &:not(:last-of-type)
      margin-right 10px

.target-con
  font-size 20px
  position relative

  margin-left 20px
  padding 20px 0
  padding-left: 20px
  padding-bottom 0

  &::after
    content ''
    position absolute
    width: 6px;
    height: 18px;
    background: #00b694;
    left: 8px !important;
    top: 26px;

</style>
